import { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Workout } from '../../../redux/features/OnDemand/onDemandWorkouts'
import { useOuterClick, useSessionStorage } from '../../hooks'
import { workoutsApi } from '../../networking/workouts'
import { TagsDTO } from '../../types/workout'
import {
  analytics,
  analyticsEvent,
  analyticsEventLabel,
} from '../../utility/analytics'
import {
  formatDateTimeByDateAndMinutes,
  formatMeridianByMinutes,
} from '../../utility/datetimes'

const MINUTES_IN_INTERVAL = 15
const TIME_MIN = 0
const TIME_MAX = 95.999
const ALLOWEDS_TAG_TITLE = [
  'STRENGTH',
  'BEGINNER',
  'LOW IMPACT',
  'YOGA',
  'CAMP',
  'HIGH-INTENSITY CARDIO',
  'PILATES',
  'CG POWER CYCLE',
  'CG RHYTHM CYCLE',
]

const { fetchWorkoutTags } = workoutsApi()

const { trackCustomEvent } = analytics
const { BUTTON_CLICK } = analyticsEvent
const {
  WORKOUTS: { FILTERS, SHOW_WORKOUTS },
} = analyticsEventLabel

type UseTimeFilterProps = {
  activeDayOfWeek: number
  filtersApplied: boolean
  filteredNumberOfWorkouts: number
  onApplyFilterByTimeClick: (
    startTime: string,
    endTime: string,
    tagId: number | null,
  ) => void
  onClearFilterByTimeClick: () => void
  onFilterByTimeSlide: (
    startTime: string,
    endTime: string,
    isFilterVisible: boolean,
    tag: number[],
  ) => void
  onOverlayIsSet: (toBeVisible: boolean) => void
  virtualWorkoutsPath?: boolean
  workouts?: Workout[]
}

const useFilters = ({
  filtersApplied,
  filteredNumberOfWorkouts,
  onApplyFilterByTimeClick,
  onClearFilterByTimeClick,
  onFilterByTimeSlide,
  onOverlayIsSet,
  activeDayOfWeek,
  virtualWorkoutsPath,
}: UseTimeFilterProps) => {
  const [initialMinValue, setInitialMinValue] = useState<number>(TIME_MIN)
  const [initialMaxValue, setInitialMaxValue] = useState<number>(TIME_MAX)
  const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false)
  const [styleFilterValue, setStyleFilterValue] = useState<number | undefined>(
    undefined,
  )
  const [filterStylesIds, setFilterStylesIds] = useState<TagsDTO[]>([])
  const filterButtonName = `Show ${filteredNumberOfWorkouts} workouts`
  const filterValuesFromSession = sessionStorage.getItem('filterValues')

  const [
    filterValuesFromStorage,
    setFilterValuesFromStorage,
    removeFilterValuesFromStorage,
  ] = useSessionStorage(
    'filterValues',
    JSON.stringify({
      initialMinValue: TIME_MIN,
      initialMaxValue: TIME_MAX,
      styleFilterValue: undefined,
    }),
  )

  const timeFilterRef = useRef<HTMLElement>(null)

  const currentWorkoutDate = () => {
    if (activeDayOfWeek === moment().day()) {
      return moment().utc()
    } else if (activeDayOfWeek < moment().day()) {
      return moment()
        .utc()
        .day(activeDayOfWeek + 7)
    }
    return moment()
      .utc()
      .add(activeDayOfWeek - moment().day(), 'days')
  }

  const startTime = formatDateTimeByDateAndMinutes(
    currentWorkoutDate(),
    initialMinValue * MINUTES_IN_INTERVAL,
  )
  const endTime = formatDateTimeByDateAndMinutes(
    currentWorkoutDate(),
    initialMaxValue * MINUTES_IN_INTERVAL,
  )
  const startTimeLabelWhenApplied = `${formatMeridianByMinutes(
    initialMinValue * MINUTES_IN_INTERVAL,
  )} - ${formatMeridianByMinutes(initialMaxValue * MINUTES_IN_INTERVAL)}`

  const showFiltersListAndOverlay = (toBeVisible: boolean) => {
    setAreFiltersVisible(toBeVisible)
    onOverlayIsSet(toBeVisible)
  }

  const handleFiltersBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    showFiltersListAndOverlay(true)
    trackCustomEvent(BUTTON_CLICK(FILTERS))
  }

  const handleCloseBtnClick = () => {
    showFiltersListAndOverlay(false)
  }

  const handleStyleFilterChange = (tagId?: number) => setStyleFilterValue(tagId)

  const handleOnSliderChangeValue = (minValue: number, maxValue: number) => {
    setInitialMinValue(minValue)
    setInitialMaxValue(maxValue)
  }

  const handleFilterClick = () => {
    const tagId =
      filterStylesIds.find((tag) => tag.id === styleFilterValue)?.id || null
    onApplyFilterByTimeClick(startTime, endTime, tagId)
    trackCustomEvent(BUTTON_CLICK(SHOW_WORKOUTS))

    handleCloseBtnClick()
  }

  const handleClearBtnClick = () => {
    onClearFilterByTimeClick()
    setInitialMinValue(TIME_MIN)
    setInitialMaxValue(TIME_MAX)
    setStyleFilterValue(undefined)
    removeFilterValuesFromStorage()
    handleCloseBtnClick()
  }

  const handleClearFiltersOnReload = () => {
    setInitialMinValue(TIME_MIN)
    setInitialMaxValue(TIME_MAX)
    setStyleFilterValue(undefined)
    removeFilterValuesFromStorage()
  }

  const handleSliderInputOnClick = () => {
    if (areFiltersVisible) {
      onFilterByTimeSlide(
        startTime,
        endTime,
        areFiltersVisible,
        styleFilterValue ? [styleFilterValue] : [],
      )
    }
  }

  useEffect(() => {
    if (areFiltersVisible) {
      onFilterByTimeSlide(
        startTime,
        endTime,
        areFiltersVisible,
        styleFilterValue ? [styleFilterValue] : [],
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areFiltersVisible])

  useEffect(() => {
    fetchWorkoutTags().then((res) => {
      const styleTags = res.filter(
        (tag) =>
          tag.tagType === 'style' &&
          ALLOWEDS_TAG_TITLE.includes(tag.title.toUpperCase()),
      )
      setFilterStylesIds(styleTags)
    })
  }, [])

  useOuterClick(timeFilterRef, () => {
    handleCloseBtnClick()
  })

  useEffect(() => {
    onFilterByTimeSlide(
      startTime,
      endTime,
      areFiltersVisible,
      styleFilterValue ? [styleFilterValue] : [],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime, styleFilterValue])

  useEffect(() => {
    if (filtersApplied) {
      setFilterValuesFromStorage({
        initialMinValue,
        initialMaxValue,
        styleFilterValue,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMinValue, initialMaxValue, styleFilterValue, filtersApplied])

  useEffect(() => {
    if (filterValuesFromStorage) {
      setInitialMinValue(filterValuesFromStorage.initialMinValue)
      setInitialMaxValue(filterValuesFromStorage.initialMaxValue)
      setStyleFilterValue(filterValuesFromStorage.styleFilterValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (filtersApplied) {
      handleFilterClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDayOfWeek])

  useEffect(() => {
    if (virtualWorkoutsPath) {
      handleClearFiltersOnReload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualWorkoutsPath])

  useEffect(() => {
    if (filterValuesFromSession === null) {
      setInitialMinValue(TIME_MIN)
      setInitialMaxValue(TIME_MAX)
      setStyleFilterValue(undefined)
    }
  }, [filterValuesFromSession])

  return {
    areFiltersVisible,
    filterButtonName,
    filterStylesIds,
    initialMaxValue,
    initialMinValue,
    startTimeLabelWhenApplied,
    styleFilterValue,
    TIME_MAX,
    timeFilterRef,
    handleClearBtnClick,
    handleCloseBtnClick,
    handleFilterClick,
    handleFiltersBtnClick,
    handleOnSliderChangeValue,
    handleSliderInputOnClick,
    handleStyleFilterChange,
  }
}

export default useFilters
